import ChooseWorkoutMode, { Props } from "./ChooseWorkoutMode/ChooseWorkoutMode"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../redux/store"
import React, { useEffect, useState } from "react"
import ActivateMembership from "./ActivateMembership/ActivateMembership"
import { checkMembership, clean } from "./ActivateMembership/activateMembershipSlice"
import withLocation from "../../components/withLocation"
import StyledSnackbar from "../../components/StyledSnackbar"

interface AMProps extends Props {
  search?: any
}

let activeMessageCount = 0

function ActiveMemberWorkoutModeChoice(props: AMProps) {
  const {
    showMembershipPlans,
    membershipCheckTimestamp,
    error
  } = useSelector((state: RootState) => state.activateMembership)
  const dispatch = useDispatch()
  const [propsChangeTimestamp, setPropsChangeTimestamp] = useState(0)
  const { stripe } = props.search


  useEffect(() => {
    if(props?.workout?.id) {
      setPropsChangeTimestamp(Date.now())
      dispatch(checkMembership())
    }
  }, [props.workout?.id])

  function SuccessErrorComponents() {
    return <div>
      {error && <StyledSnackbar show={true} severity={"error"} message={error.message}
                                showTime={error.errorTime}/>}
      {stripe == "success" && (activeMessageCount++ <= 2) &&
      <StyledSnackbar show={true} severity={"success"} message={`Your membership is now active!`}/>}
    </div>
  }


  // we only decide what modal to show once we have ensured the membership has been checked by the latest trigger
  if (membershipCheckTimestamp > propsChangeTimestamp && props.workout) {
    return (
      <div>
        {showMembershipPlans && <ActivateMembership cleanup={() => {
          dispatch(clean())
          props.cleanup()
        }}/>}
        {!showMembershipPlans && <ChooseWorkoutMode {...props}/>}
        <SuccessErrorComponents/>
      </div>

    )
  }
  return <SuccessErrorComponents/>

}

export default withLocation(ActiveMemberWorkoutModeChoice)