import { makeStyles } from "@material-ui/core/styles"
import React, { useState } from "react"
import { Box, Typography } from "@material-ui/core"
import StyledButton from "../../../components/StyledButton"
import { CopyToClipboard } from "react-copy-to-clipboard"
import StyledSnackbar from "../../../components/StyledSnackbar"
import { WorkoutVideo } from "../types"
import AddToCalendar, { convertWorkoutToCalendarProps } from "./AddToCalendar"

const useStyles = makeStyles((theme) => ({

  container: {
    padding: theme.spacing(0, 3)
  },
  title: {
    textTransform: "uppercase"
  },
  message: {
    marginTop: theme.spacing(3)
  },
  link: {
    marginTop: theme.spacing(1),
    color: theme.palette.primary.main,
    [`@media (hover: hover)`]: {
      "&:hover": {
        cursor: "pointer",
        opacity: 0.7
      }
    }
  },

  button: {
    width: "270px",
    margin: `${theme.spacing(5)}px auto 0px auto`
  },

  calendar: {
    width: "270px",
    margin: `${theme.spacing(3)}px auto 0px auto`
  }
}))

export interface Props {
  workout: WorkoutVideo
  inviteLink: string
  inviteText: string
}

export default function InviteFriendsSuccess(props: Props) {
  const classes = useStyles()
  const [linkCopyTime, setLinkCopyTime] = useState(0)

  function shareLinkClicked() {
    setLinkCopyTime(Date.now())
  }

  return <div className={classes.container}>
    <Typography align={"center"} variant={"h1"} className={classes.title}>Your own (virtual) space</Typography>
    <Typography align={"center"} variant={"body2"} className={classes.message}>With this link, you can workout together
      with your friends (upto 8) and see how each of you are performing.</Typography>
    <CopyToClipboard text={props.inviteText} onCopy={shareLinkClicked}>
      <Typography align={"center"} variant={"body2"} className={classes.link}>{props.inviteLink}</Typography>
    </CopyToClipboard>
    <CopyToClipboard text={props.inviteText} onCopy={shareLinkClicked}>
      <StyledButton className={classes.button} text={"copy invite"}/>
    </CopyToClipboard>
    <StyledSnackbar show={linkCopyTime > 0} message={"Copied to Clipboard!"} severity={"info"} showTime={linkCopyTime}/>
    <Box className={classes.calendar}>
      <AddToCalendar {...convertWorkoutToCalendarProps(props.workout, "center")}/>
    </Box>
  </div>
}