import { makeStyles } from "@material-ui/core/styles"
import { Backdrop, Box, CircularProgress, Typography } from "@material-ui/core"
import CloseButton from "../../../components/CloseButton"
import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../../redux/store"
import StyledButton from "../../../components/StyledButton"
import { startCheckout } from "./activateMembershipSlice"
import { STRIPE_DATA } from "../../../env"

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "90%",
    color: "white"
  },
  grid: {
    display: "flex",
    alignContent: "flex-start",
    width: "99%",
    [theme.breakpoints.down("xs")]: {
      width: "auto",
      flexWrap: "wrap",
      alignContent: "center"
    }
  },
  backdrop: {
    flexDirection: "column",
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: "rgba(0, 0, 0, 0.92)"
  },
  paidMembershipText: {
    color: "#fff",
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(0, 4)
    }
  },
  okButton: {
    width: "240px",
    margin: "auto"
  }

}))

export interface Props {
  cleanup: () => void
}

export default function ActivateMembership(props: Props) {
  const classes = useStyles()
  const loading = useSelector((state: RootState) => state.activateMembership.loading)

  const dispatch = useDispatch()

  useEffect(() => {
    handleStripePayment(STRIPE_DATA.weeklyProductId)
  }, [])

  function handleStripePayment(membershipType: string) {
    dispatch(startCheckout(membershipType))
  }

  return (<Backdrop open={true} className={classes.backdrop}>
    <CloseButton clicked={props.cleanup}/>

    <div className={classes.root}>
      {loading && <CircularProgress/>}
      {!loading && false && <div>
        <Typography align={"center"} variant={"h2"}>ACTIVATE MEMBERSHIP</Typography>
        <div>
          <Box mt={4} mb={3}>
            <Typography align={"center"} variant={"body2"}>
              Subscribe to our SGD 10/week plan to access content.
              <br/>
              You will only be charged after your 7-day trial has ended. Cancel at anytime :)
            </Typography>
          </Box>
          <StyledButton className={classes.okButton} text={"SUBSCRIBE NOW"}
                        onClick={() => handleStripePayment(STRIPE_DATA.weeklyProductId)}/>
        </div>
      </div>}
    </div>

  </Backdrop>)
}